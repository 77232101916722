require('./bootstrap.js')

function increment_last(v) {
    return v.replace(/[0-9]+(?!.*[0-9])/, function(match) {
        return parseInt(match, 10)+1;
    });
}

$(document).ready(function() {

    $.ajaxSetup({
        headers: {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
        }
    });

   // $('.datatable').DataTable();
    $('#datatable').DataTable( {
        render:  $.fn.dataTable.render.text(),
        lengthMenu:    [100,200,300],
        language: {
            processing:     "Traitement en cours...",
            search:         "Rechercher&nbsp;",
            lengthMenu:    "Afficher _MENU_",
            info:           "Affichage de l'&eacute;lement _START_ &agrave; _END_ sur _TOTAL_ &eacute;l&eacute;ments",
            infoEmpty:      "Affichage de l'&eacute;lement 0 &agrave; 0 sur 0 &eacute;l&eacute;ments",
            infoFiltered:   "(filtr&eacute; de _MAX_ &eacute;l&eacute;ments au total)",
            infoPostFix:    "",
            loadingRecords: "Chargement en cours...",
            zeroRecords:    "Aucun &eacute;l&eacute;ment &agrave; afficher",
            emptyTable:     "Aucune donnée disponible dans le tableau",
            paginate: {
                first:      "Premier",
                previous:   "Pr&eacute;c&eacute;dent",
                next:       "Suivant",
                last:       "Dernier"
            },
            aria: {
                sortAscending:  ": activer pour trier la colonne par ordre croissant",
                sortDescending: ": activer pour trier la colonne par ordre décroissant"
            }
        }
    } );

    $('#add_speclabel').click( function (e) {
        e.preventDefault();

        var index = $('.speclabel').length + 1;

        var clone=$('#speclabel-template').html()

        $('.speclabels').append(clone.replace(/#i#/g,index))
    });

    $(document).delegate('button.js-movelabel','click',function(e){
        e.preventDefault();
        const direction=$(this).data('direction')
        const label=$(this).closest('.speclabel')
        if(direction==='down') {
            const nextlabel=$(label).next('.speclabel');
            if(nextlabel!=='undefined') {
                $(label).insertAfter($(nextlabel));
            }
        }
        if(direction==='up') {
            const prevlabel=$(label).prev('.speclabel');
            if(prevlabel!=='undefined') {
                $(label).insertBefore($(prevlabel));
            }
        }
    })

    $('#add_classification').click(function(e){
        e.preventDefault()
        // On clone la derniere ligne de label
        $(".classification-card").last().clone().appendTo($(".classification-container"));
        var new_classification = $(".classification-card").last();
        var new_index = new_classification.data('index')+1;
        new_classification.attr('data-index', new_index);

        var delete_btn = new_classification.find('.delete-classification-card');
        delete_btn.attr('data-index', new_index);

        // On reset les champs du nouveau clone
        new_classification.find('.form-group').each(function()  {
            var label = $(this).find('.control-label');
            label.attr('for', increment_last(label.attr('for')));

            var select = $(this).find('.form-control');
            select.attr('name', increment_last(select.attr('name')));
            select.attr('id', increment_last(select.attr('id')));
            select.val('');
        });
    });

    $('#add_product').click(function(){
        // On clone la derniere ligne de label
        $(".product").last().clone().appendTo($(".products"));
        var new_label = $(".product").last();

        // On reset les champs du nouveau clone
        new_label.find('.form-control').val('');

        // On incremente ses attributs name selon le nombre de labels
        var label_select = new_label.find('select.form-control');
        label_select.attr('name', increment_last(label_select.attr('name')));

        var label_value = new_label.find('input.form-control');
        label_value.attr('name', increment_last(label_value.attr('name')));
    });

    $(document).delegate('.submitOnChange','change',function() {
        $(this).parent('form').submit()
    })

    $(document).on("click", "a.delete-classification-card" , function() {
        $(".classification-card[data-index='"+$(this).data('index')+"']").remove();
    });

    $(".delete-label").click(function() {
        $(this).closest('.speclabel').remove();
    });

    $(document).on("click", "a.delete-speclabel" , function() {

        $(".speclabel[data-index='"+$(this).data('index')+"']").remove();

        $(".speclabel").each(function(order) {
            var newIndex = (order+1);
            $(this).attr('data-index', newIndex);
            $(this).find('.delete-speclabel').attr('data-index', newIndex);

            var label_select = $(this).find('select.form-control');
            label_select.attr('name', label_select.attr('name').replace(/[0-9]+(?!.*[0-9])/, newIndex));

            var label_value = $(this).find('textarea.form-control');
            label_value.attr('name', label_value.attr('name').replace(/[0-9]+(?!.*[0-9])/, newIndex));
        });

    });

    $(document).on('click','.edit-post', function (e) {
        e.preventDefault();

        var form = $(this).closest('form');

        form.find('.raw-content').hide();
        form.find('.form-content').show();

    });

    $(document).on('click','.add-post', function (e) {
        e.preventDefault();

        var element_clicked = $(this);
        var type=$(this).data('type');

        $.ajax({
            url: '/ajax/post/create',
            type: 'POST',
            data: {post_type: element_clicked.data('type')},
            dataType: 'json',
            success: function (data) {
                $('#wrap-'+type).prepend(data.view)

                var form = $('#form-'+data.id);

                form.find('.raw-content').hide();
                form.find('.form-content').show();
            },
            error: function (xhr, ajaxOptions, thrownError) {
                console.log(thrownError + "\r\n" + xhr.statusText + "\r\n" + xhr.responseText);
            }
        });
    });

    $(document).on('submit','.form-post', function (e) {
        e.preventDefault();

        var form = $(this);
        var formData = new FormData($(this)[0]);
        $.ajax({
            url: '/ajax/post/update',
            type: 'POST',
            data: formData,
            async: false,
            cache: false,
            contentType: false,
            enctype: 'multipart/form-data',
            processData: false,
            dataType: 'json',
            success: function (data) {
                $('#form-'+form.data('id')).replaceWith(data.view)
            },
            error: function (xhr, ajaxOptions, thrownError) {
                console.log(thrownError + "\r\n" + xhr.statusText + "\r\n" + xhr.responseText);
            }
        });
    });

    $(document).on('click','.delete-post', function (e) {
        e.preventDefault();

        var element_clicked=$(this);

        if(confirm('Voulez-vous effacer ce post ?')) {
            $.ajax({
                url: '/ajax/post/delete',
                type: 'POST',
                data: {
                    "_method": 'POST',
                    "_token": element_clicked.data('csrf_token'),
                    "id": element_clicked.data('id'),
                },
                dataType: 'json',
                success: function () {
                    $('#form-' + element_clicked.data('id')).remove();
                },
                error: function (xhr, ajaxOptions, thrownError) {
                    console.log(thrownError + "\r\n" + xhr.statusText + "\r\n" + xhr.responseText);
                }
            });
        }
    });

    $(document).on('change','.link_type', function (e) {
        e.preventDefault();

        var id=$(this).data('id');

        $('.link_content-'+id).hide();
        $('#link-'+$(this).val()+'-'+id).show();
    });

});
